<template>
    <section class="main-content-section">
        <div class="grid grid-pad">
            <div class="col-1-1 center">
                <h2 class="page-title">{{ pageNotFound }}</h2>
            </div>
            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="textpage flex flex-column align-center justify-center">
                    <p>{{thisPageIsNotAvailable}}</p>
                    <router-link to="/" class="btn btn-primary alternative">{{backToHomepage}}</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PageNotFound',

    computed: {
        ...mapGetters(['metaTitle', 'pageNotFound', 'thisPageIsNotAvailable', 'backToHomepage'])
    },

    created() {
        this.setDocumentTitle();
    },

    updated() {
        this.setDocumentTitle();
    },

    methods: {
        setDocumentTitle() {
            document.title = this.pageNotFound + this.metaTitle;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.textpage {
    border-radius: 8px;
    box-shadow: $shadow-1;
    padding: 6rem;
    background: $white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
    text-align: center;
    
    p {
        margin-bottom: 5rem;
    }
    
    p a,
    li a {
        display: inline-block;
        color: inherit;
        text-decoration: underline;
    }

    &-title {
        font-size: 2.8rem;
        font-weight: 300;
        line-height: normal;
        display: block;
        text-align: center;
        margin: 0 0 2rem 0;
    }
}

@media only screen and (max-width: 400px) {
    .textpage {
        padding: 6rem 2rem;
    }
}
</style>